// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-users-[userid]-edit-js": () => import("./../../../src/pages/admin/users/[userid]/edit.js" /* webpackChunkName: "component---src-pages-admin-users-[userid]-edit-js" */),
  "component---src-pages-admin-users-add-js": () => import("./../../../src/pages/admin/users/add.js" /* webpackChunkName: "component---src-pages-admin-users-add-js" */),
  "component---src-pages-admin-users-js": () => import("./../../../src/pages/admin/users.js" /* webpackChunkName: "component---src-pages-admin-users-js" */),
  "component---src-pages-admin-wineries-js": () => import("./../../../src/pages/admin/wineries.js" /* webpackChunkName: "component---src-pages-admin-wineries-js" */),
  "component---src-pages-campaigns-[campaignid]-call-js": () => import("./../../../src/pages/campaigns/[campaignid]/call.js" /* webpackChunkName: "component---src-pages-campaigns-[campaignid]-call-js" */),
  "component---src-pages-campaigns-[campaignid]-contact-js": () => import("./../../../src/pages/campaigns/[campaignid]/contact.js" /* webpackChunkName: "component---src-pages-campaigns-[campaignid]-contact-js" */),
  "component---src-pages-campaigns-index-js": () => import("./../../../src/pages/campaigns/index.js" /* webpackChunkName: "component---src-pages-campaigns-index-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-my-profile-js": () => import("./../../../src/pages/my-profile.js" /* webpackChunkName: "component---src-pages-my-profile-js" */),
  "component---src-pages-orders-js": () => import("./../../../src/pages/orders.js" /* webpackChunkName: "component---src-pages-orders-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-welcome-js": () => import("./../../../src/pages/welcome.js" /* webpackChunkName: "component---src-pages-welcome-js" */)
}

